<template>
    <div class="mainList">

        <div class="mainList_content box-shadow">
            <div class="mainList_content_operation">
                <div class="mainList_content_operation-l">

                    <el-button size="small" icon="el-icon-plus" v-if="permission(['DICT_ADD'])" @click="add('0')">新增</el-button>
                    <el-button size="small" icon="el-icon-sort" type="primary" @click="saveSort" :loading="saveSortLoading">保存排序</el-button>
                    <el-button type="danger" icon="el-icon-delete" v-if="permission(['DICT_DEL'])" size="small" @click="del()">批量删除</el-button>

                </div>
                <div class="mainList_content_operation-r">
                    <span class="colorBlue">{{  data.name  }}字典详情</span>
                </div>
            </div>
            <div class="mainList_content_sub" v-show="data.code">
                <el-table ref="multipleTable"
                          row-key="id"
                          border
                          :data="tableData"
                          :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
                          tooltip-effect="dark"
                          height="string"
                          :default-expand-all="false"
                          v-loading="loading"
                          header-cell-class-name="meterHeader"
                         >
                    <el-table-column type="selection" align="center" width="55" fixed="left"></el-table-column>
                    <el-table-column align="left" prop="name" label="名称">
                        <template slot-scope="scope">
                            <span class="listColor" :style="`color:${scope.row.isInput}`">{{  scope.row.name  }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="val" align="center" label="值"></el-table-column>

                    <el-table-column label="操作" fixed="right"  align="center" width="150"  v-if="permission(['DICT_ADD','DICT_EDIT','DICT_DEL'])">
                        <template slot-scope="scope">
                            <el-button type="text" @click="add(scope.row.id)" v-if="permission(['DICT_ADD'])">新增</el-button>
                            <el-button type="text" class="edit" @click="edit(scope.row)" v-if="permission(['DICT_EDIT'])">编辑</el-button>
                            <el-button type="text" class="del" @click="del(scope)" v-if="permission(['DICT_DEL'])">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <el-result v-show="!data.code" icon="info" title="" subTitle="点击字典查看详情"></el-result>
        </div>
        <SubEdit  :refresh="refresh" :isShow.sync="showEditDialog" :options="options" :rulesData="rulesData" :treeselectOptions="treeselectOptions"></SubEdit>

    </div>
</template>
<script>

import {initTreeData, treeToTile} from '@/util/common'
import mixinsList from '@/mixins/list'
import Sortable from "sortablejs";
export default {
    components: {
        SubEdit: () => import("./SubEdit.vue"),
    },
    props: {
        data: {
            type: Object,
            default:function(){
                return {}
            }
        },
    },
    mixins: [mixinsList],
    data() {
        return {
            api:{
                del:this.$api['admin/dict'].del,
            },
            saveSortLoading:false,
            treeselectOptions: [],
            rulesData:[],
        };
    },
    watch: {
        data:{
            immediate:true,//初始化立即执行
            deep: true,//对象深度监测
            handler: function (newVal){
                this.getData();
            }
        },
    },
    created() {
        this.getData();
    },
    mounted(){
        this.elFormTable = this.$refs.multipleTable.$el.querySelector('.el-table__body-wrapper tbody');
        new Sortable(this.elFormTable, {
            animation: 150,
            ghostClass: '.el-table__row',
            onMove:({ dragged, related }) =>{
                var arr = treeToTile(this.tableData);
                const oldRow = arr[dragged.rowIndex]; //旧位置数据
                const newRow = arr[related.rowIndex]; //被拖拽的新数据
                return oldRow.parentId == newRow.parentId;
            },
            onEnd:r=> {
                var arr = treeToTile(this.tableData);
                var currRow = arr.splice(r.oldIndex, 1)[0];
                arr.splice(r.newIndex, 0, currRow);
                this.tableData = initTreeData(arr);
            }
        });
    },
    methods: {
        saveSort(){
            var arr = treeToTile(this.tableData);
            var dragSortVos = [];
            for(let i=0;i<arr.length;i++){
                let r = arr[i]
                dragSortVos.push({
                    dictId:r.id,
                    sort:i,
                })
            };
            this.saveSortLoading = true;

            this.$api['admin/dict'].dictDragSort({dragSortVos}).then( res => {
                this.saveSortLoading = false;
                this.$message.success("操作成功！");
            }).catch(err=>{
                this.saveSortLoading = false;
                console.log(err);
            })
        },
        add(parentId) {
            this.options = {
                name:'',
                val:'',
                parentId,
                code:this.data.code
            };
            this.showEditDialog = true

        },
        getData() {
            if(this.data.code){
                this.loading = true;
                this.$api['admin/dict'].findAllDictByCode(this.data.code).then(res => {
                    this.loading = false;

                    this.rulesData = res.data;
                    const treeData = initTreeData(res.data);
                    this.tableData = treeData;
                    this.treeselectOptions = [
                        {
                            id:'0',
                            name:'顶级字典',
                            children:treeData
                        }
                    ];

                }).catch(err => {
                    console.log(err);
                    this.loading = false;
                });
            }
        },
    },
};
</script>
<style scoped lang='scss'>
    @import "@/styles/config.scss";

</style>
